.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  
  .container {
    padding: 80px 102px 156px;
  }

  .content {
    max-width: 545px;
  }

  .content-flex {
    display: flex;
    justify-content: space-between;
  }

  .title {
    color: #227263;
    font-size: 48px;
    font-weight: 700;
    max-width: 430px;
  }

  .down-site {
    width: 600px;
    height: 381px;
    margin-top: 150px;
  }

  .mail {
    color: #8737D0;
    font-weight: 600;
    font-size: 18px;
  }

  .text {
    color: #323A44;
    font-size: 18px;
    margin-bottom: 32px;
  }

  .button {
    color: #FFFFFF;
    font-weight: 600;
    padding: 14px 40px;
    border-radius: 4px;
    background: #8737D0;
    text-decoration: none;
    text-transform: uppercase;
  }

  .footer {
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0F7057;
  }

  @media (max-width: 1200px) {
    .container {
      padding: 76px 24px 52px;
    }

    .content {
      order: 1;
    }

    .down-site {
      width: 340px;
      height: 216px;
      margin-top: 30px;
    }

    .content-flex {
      align-items: center;
      flex-direction: column;
    }
  }
 
  @media (max-width: 720px) {
    .title {
      font-size: 40px;
      max-width: 350px;
    }
  }
}


